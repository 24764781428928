@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Quantify';
    src: url('./assets/fonts/Quantify.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Aptos';
    src: url('./assets/fonts/Aptos.ttf') format('truetype');
  }

  html,body {
    @apply font-aptos;
  }
  body {
    background-color: #0A1A2E;    
  } 